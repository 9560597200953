@import '@/var';
$fontFamily: "Monospaced Number", "Chinese Quote", -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB",
  "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif !default;

html,
body,
div,
ul,
ol,
li,
dl,
dt,
dd,
p,
span,
em,
strong,
b,
i,
form,
iframe,
table,
thead,
tbody,
input,
button,
video,
textarea,
select,
option,
img,
a,
xmp,
pre,
code,
h1,
h2,
h3,
h4,
h5,
h6,
canvas {
  margin: 0;
  padding: 0;
}
div {
  box-sizing: border-box;
}
html {
  font-family: $fontFamily;
}
html,
body,
#page-container {
  width: 100%;
  height: 100%;
}
#page-container {
  display: flex;
  flex-direction: column;
  > div {
    z-index: 1;
    flex: 1;
    padding: pr(12);
    background: #f7f7f7;
  }
}

:root {
  --portrait-text: "";
}

/**
 * @description: margin padding 快捷设置
 */
$directions: (
  l: "left",
  r: "right",
  b: "bottom",
  t: "top"
);
@for $size from 0 through 48 {
  .m-#{$size} {
    margin: #{$size}px;
  }
  .p-#{$size} {
    padding: #{$size}px;
  }
  @each $dir, $direction in $directions {
    .m#{$dir}-#{$size} {
      margin-#{$direction}: #{$size}px;
    }
    .p#{$dir}-#{$size} {
      padding-#{$direction}: #{$size}px;
    }
  }
}

.iconfont {
  fill: currentColor;
}

///antd-m
#page-container {
  .adm-search-bar .adm-search-bar-input-box {
    border-radius: pr(20);
    background: #fff;
    border: 1px solid #efefef;
    height: 40px;
  }
}

#editor {
  height: 100%;
  .w-e-toolbar {
    display: none;
  }
  .w-e-text-container {
    border: 0 !important;
    z-index: 1 !important;
    height: 100% !important;
    overflow-y: auto;
    .w-e-text {
      min-height: auto !important;
    }
  }
  .w-e-toolbar p,
  .w-e-text-container p,
  .w-e-menu-panel p {
    font-size: 14px !important;
  }
}
:root:root {
  --adm-color-primary: #1ebf6e;
}
